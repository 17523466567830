import React from 'react';
import { defineMessages } from 'react-intl';
import Layout from '../../components/Layout/Layout';
import Login from './Login';

const messages = defineMessages({
  titleLogIn: {
    id: 'title.Login',
    defaultMessage: 'Login',
    description: 'Log in page title',
  },
  loginDescription: {
    id: 'login.description',
    defaultMessage: 'Login Description',
    description: 'Log in page description',
  },
});

function action({ intl }) {
  const title = intl.formatMessage(messages.titleLogIn);
  const description = intl.formatMessage(messages.loginDescription);
  return {
    chunks: ['login'],
    title,
    description,
    canonicalUrl: 'https://my-account.storage-mart.com/login',
    component: (
      <Layout>
        <Login title={title} />
      </Layout>
    ),
  };
}

export default action;
